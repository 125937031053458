  .animated-sidebar {
      animation: ease-in-out;
      -webkit-animation-duration: 500ms;
      animation-duration: 500ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      display: hidden;
    }
  
  .fadeIn-sidebar {
    -webkit-animation-name: fadeIn-sidebar;
    animation-name: fadeIn-sidebar;
  }
  
  .fadeOut-sidebar {
    -webkit-animation-name: fadeOut-sidebar;
    animation-name: fadeOut-sidebar;
  }
  
  @keyframes fadeIn-sidebar {
    from {
      left: -24rem;
      opacity: 0;
    }
  
    to {
      left: 0;
      opacity: 1;
    }
  }
  
  @keyframes fadeOut-sidebar {
    from {
        left: 0;
        opacity: 1;
    }
  
    to {
        left: -24rem;
        opacity: 0;
    }
  }
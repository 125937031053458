table {
    border-collapse: collapse;
    width: 90%;
    margin: auto;
    
  }

  th{

  }
  
  td, th {
    text-align: center;
    padding: 8px;
  }

tbody tr:nth-child(odd) {
    
    background-color: #122431af;
}
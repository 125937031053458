.animated-modal {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeIn-modal {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.fadeOut-modal {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} 

.app {
  min-height: 100vh;
  height: max-content;
}
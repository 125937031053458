.spinner-overlay {
  height: 20vh;
  width: 91.66667%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.spinner-container {
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
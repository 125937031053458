.lds-ring {
    display: flex;
    width: 147px;
    justify-content: center;
  }
  .lds-ring div {
    display: block;
    width: 16px;
    height: 16px;
    margin: 4px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  
  .lds-ring div.white {
    border-color: rgb(248, 248, 248) transparent transparent transparent;
  }
  
  .lds-ring div.black {
    border-color: rgb(0, 0, 0) transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }